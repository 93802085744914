import type { FormValue } from "~/types/common";

import { createContext, useContext, type ParentComponent } from "solid-js";
import { createStore } from "solid-js/store";
import type { SearchResults } from "~/types/drupal_jsonapi";
import type { CountsState } from "~/components/Search/SearchForm";

export type SearchContext = [
  {
    searchBarIsOpen: boolean;
    advancedSearchIsOpen: boolean;
    values: Record<string, FormValue>;
    searchResults?: SearchResults;
    counts?: CountsState["result"];
  },
  {
    setSearchBarOpen?: () => void;
    setSearchBarClose?: () => void;
    setAdvancedSearchOpen?: () => void;
    setAdvancedSearchClose?: () => void;
    setSearchResults?: (results: SearchResults) => void;
    setValue?: (name: string, value: FormValue) => void;
    setCounts?: (result: CountsState["result"]) => void;
  },
];

const SearchContext = createContext<SearchContext>([
  {
    searchBarIsOpen: false,
    advancedSearchIsOpen: false,
    searchResults: undefined,
    values: {},
  },
  {},
]);

export const SearchProvider: ParentComponent = (props) => {
  const [searchState, setState] = createStore({
      searchBarIsOpen: false,
      advancedSearchIsOpen: false,
      searchResults: undefined,
      values: {},
      counts: undefined,
    }),
    store: SearchContext = [
      searchState,
      {
        setSearchBarOpen() {
          setState("searchBarIsOpen", true);
        },
        setSearchBarClose() {
          setState("searchBarIsOpen", false);
        },
        setAdvancedSearchOpen() {
          setState("advancedSearchIsOpen", true);
        },
        setAdvancedSearchClose() {
          setState("advancedSearchIsOpen", false);
        },
        setSearchResults(results: SearchResults) {
          setState("searchResults", results);
        },
        setValue(name: string, value: FormValue) {
          setState("values", (prev) => ({ ...prev, [name]: value }));
        },
        setCounts(result: CountsState["result"]) {
          setState("counts", result);
        },
      },
    ];

  return (
    <SearchContext.Provider value={store}>
      {props.children}
    </SearchContext.Provider>
  );
};

export function useSearchContext() {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearchContext must be used within a SearchProvider");
  }
  return context;
}
